// The initialize function must be assigned each time a new page is loaded.
Office.initialize = function (reason) {
    // If you need to initialize something you can do so here.
    // Office.context.document.settings.refreshAsync();
};

import DataStore from "../utils/DataStore";

let entities;
let selectedEntity;
let selectedEntityID;
let debug = false;

async function inject(report, isMulti = false) {
    try {
        await Excel.run(async (context) => {
            const debugRange = context.workbook.worksheets.getActiveWorksheet().getRange("A1");
            debugRange.load("values");
            await context.sync();

            if (debugRange.values[0][0] === "DEBUG") {
                debug = true;
            }

            const resources = await DataStore.getResources();
            entities = resources["entities"];
            if (!entities) {
                throw new Error("Please login");
            }

            try {
                const selectedEntityStore = await DataStore.getSelectedEntityStore();
                selectedEntity = selectedEntityStore.label;
                selectedEntityID = selectedEntityStore.value;
            } catch (error) {
                selectedEntity = entities[0].name;
                selectedEntityID = entities[0].id;
            }

            let forecast = false;
            if (report.split("_")[0] === "FOURWAY") {
                forecast = true;
            }

            const reportTypes = ["Standard", "Multi-Period"];
            const periods = ["Monthly", "Quarterly", "Weekly", "Daily", "Annual (Previous 12m)", "Annual (Financial Year)"];

            let headers = ["Entity", "View as", "Date"];

            if (forecast) {
                headers.pop();
                periods.splice(2,5,"Yearly")
            }
            if (isMulti) {
                headers.push("Report");
            }

            const range = context.workbook.getSelectedRange().getAbsoluteResizedRange(3, headers.length);

            const headersRange = range.getRow(0);
            const paramsRange = range.getRow(1);
            const formulaRange = range.getRow(2).getColumn(0);
            headersRange.values = [headers];

            await context.sync();

            const entityRange = paramsRange.getColumn(0);
            entityRange.dataValidation.rule = {
                list: {
                    inCellDropDown: true,
                    source: (entities.map(entitiy => entitiy.name)).join()
                }
            };
            if (selectedEntity) {
                entityRange.values = [[selectedEntity]]
            } else {
                entityRange.values = [[entities[0].name]]
            }

            const periodRange = paramsRange.getColumn(1);
            periodRange.dataValidation.rule = {
                list: {
                    inCellDropDown: true,
                    source: periods.join()
                }
            };
            if (forecast) {
                try{ // finds the interval of the selected forecast and sets the selector to that
                    const configStore = await DataStore.getConfigStore();
                    const selectedForecastName = configStore[selectedEntityID]["fourwayForecast"].label;
                    const selectedForecast = entities.find(entity => entity.id === selectedEntityID).fourway_forecast.find(forecast => forecast.name === selectedForecastName);
                    periodRange.values = [[selectedForecast.interval]];
                } catch (error) {
                    periodRange.values = [[periods[0]]];
                }
            } else {
                periodRange.values = [[periods[0]]];
            }
            
            let dateRange;
            if(!forecast) { // if (headers.length > 2)
                dateRange = paramsRange.getColumn(2);
                dateRange.dataValidation.rule = {
                    date: {
                        operator: "GreaterThan",
                        formula1: "2016/01/01"
                    }
                };
                dateRange.dataValidation.errorAlert = {
                    message: "Enter a valid date after 2016/01/01"
                }
                let today = new Date();
                let tomorrow = new Date();
                tomorrow.setDate(today.getDate() + 1);
                if (tomorrow.getMonth() === today.getMonth()) { //if today is not the last day of the month, set date to last day of last month
                    today.setDate(0);
                }
                const date = today.getFullYear() + '/' + (today.getMonth() + 1) + '/' + today.getDate();
                dateRange.values = [[date]];
            }

            if (isMulti) { // if (headers.length > 3)
                const reportTypeRange = paramsRange.getColumn(headers.length - 1)
                let buildOption = (report === "PROFIT_AND_LOSS" || report === "BALANCE_SHEET") ? (report === "PROFIT_AND_LOSS" ? "Build P&L" : "Build BS") : null
                reportTypeRange.dataValidation.rule = {
                    list: {
                        inCellDropDown: true,
                        source: [...reportTypes, buildOption].join()
                    }
                }
                reportTypeRange.values = [[reportTypes[0]]];
            }

            let paramArr = [];
            for (let i = 0; i < headers.length; i++) {
                paramArr.push(paramsRange.getColumn(i))
                paramArr[i].load("address");
            }

            headersRange.load("columnCount");

            await context.sync();

            let params = "(" + (paramArr.map(value => value.address)).join() + ")";
            let prefix = "=SYFT.";
            formulaRange.values = [[prefix + report + params]];

            if (headersRange.columnCount<4){
                const headersRangeExcess = headersRange.getColumnsAfter(1).getAbsoluteResizedRange(2,4 - headersRange.columnCount)
                const emptyValues = Array(4 - headersRange.columnCount).fill("")
                headersRangeExcess.values = [emptyValues,emptyValues];
                headersRangeExcess.dataValidation.clear();
            }

            await context.sync();
        });
    } catch (error) {
        if (debug) {
            print("Please login " + error);
        }
        else {
            print("Please login");
        }
    }
}

async function print(value) {
    try {
        await Excel.run(async (context) => {
            const range = context.workbook.getSelectedRange().getAbsoluteResizedRange(1, 1);
            range.values = [[value]];
            await context.sync();
        });
    } catch (error) {
        console.error(error);
    }
}

async function profit_and_loss(event) {
    await inject("PROFIT_AND_LOSS", true);
    event.completed();
}
async function balance_sheet(event) {
    await inject("BALANCE_SHEET", true);
    event.completed();
}
async function trial_balance(event) {
    await inject("TRIAL_BALANCE", true);
    event.completed();
}
async function cash_flow_direct(event) {
    await inject("CASH_FLOW_DIRECT", true);
    event.completed();
}
async function cash_flow_indirect(event) {
    await inject("CASH_FLOW_INDIRECT", true);
    event.completed();
}
async function changes_in_equity(event) {
    await inject("CHANGES_IN_EQUITY");
    event.completed();
}
async function receivables(event) {
    await inject("RECEIVABLES");
    event.completed();
}
async function payables(event) {
    await inject("PAYABLES");
    event.completed();
}
async function sales_by_customer(event) {
    await inject("SALES_BY_CUSTOMER");
    event.completed();
}
async function sales_by_product(event) {
    await inject("SALES_BY_PRODUCT");
    event.completed();
}
async function transaction_list(event) {
    await inject("TRANSACTION_LIST");
    event.completed();
}
async function fourway_forecast_pnl(event) {
    await inject("FOURWAY_FORECAST_PNL");
    event.completed();
}
async function fourway_forecast_bs(event) {
    await inject("FOURWAY_FORECAST_BS");
    event.completed();
}
async function fourway_forecast_cfd(event) {
    await inject("FOURWAY_FORECAST_CFD");
    event.completed();
}
async function fourway_forecast_cfi(event) {
    await inject("FOURWAY_FORECAST_CFI");
    event.completed();
}
async function fourway_forecast_kpi(event) {
    await inject("FOURWAY_FORECAST_KPI");
    event.completed();
}
async function fourway_forecast_drivers(event) {
    await inject("FOURWAY_FORECAST_DRIVERS");
    event.completed();
}


Office.actions.associate("profit_and_loss", profit_and_loss);
Office.actions.associate("balance_sheet", balance_sheet);
Office.actions.associate("trial_balance", trial_balance);
Office.actions.associate("cash_flow_direct", cash_flow_direct);
Office.actions.associate("cash_flow_indirect", cash_flow_indirect);
Office.actions.associate("changes_in_equity", changes_in_equity);
Office.actions.associate("receivables", receivables);
Office.actions.associate("payables", payables);
Office.actions.associate("sales_by_customer", sales_by_customer);
Office.actions.associate("sales_by_product", sales_by_product);
Office.actions.associate("transaction_list", transaction_list);
Office.actions.associate("fourway_forecast_pnl", fourway_forecast_pnl);
Office.actions.associate("fourway_forecast_bs", fourway_forecast_bs);
Office.actions.associate("fourway_forecast_cfd", fourway_forecast_cfd);
Office.actions.associate("fourway_forecast_cfi", fourway_forecast_cfi);
Office.actions.associate("fourway_forecast_kpi", fourway_forecast_kpi);
Office.actions.associate("fourway_forecast_drivers", fourway_forecast_drivers);

// Office.actions.associate("test", test);
// Office.actions.associate("test2", test2);

// async function test(event) {
//     await ribbonButtonState(false)
//     event.completed();
// }

// async function test2(event) {
//     await ribbonButtonState(true)
//     event.completed();
// }

// const ribbonButtonState = async (state) => {
//     Office.ribbon.requestUpdate({
//         tabs: [
//             {
//                 id: "SyftTab", groups: [
//                     {
//                         id: "MyAccount", controls: [
//                             { id: "MyAccountButton", enabled: state },]
//                     },
//                     {
//                         id: "Test", controls: [
//                             { id: "TestButton", enabled: state },]
//                     },
//                     {
//                         id: "Financials", controls: [
//                             {
//                                 id: "FinancialsMenu", items: [
//                                     { id: "FinancialMenu.Item1", enabled: state }]
//                             }]
//                     },
//                     // { id: "Analyze", controls: [{ id: "Financials.Menu", enabled: state }] },
//                 ]
//             }
//         ]
//     });
// }

// Office.actions.associate("eb", eb);
// Office.actions.associate("db", db);

// function disableButton() {
//     Office.ribbon.requestUpdate({
//         tabs: [
//             {
//                 id: "SyftTab",
//                 groups: [
//                     {
//                         id: "CommandsGroup",
//                         controls: [
//                             {
//                                 id: "LoginButton",
//                                 enabled: false
//                             }
//                         ]
//                     }
//                 ]
//             }
//         ]
//     });
// }
// function enableButton() {
//     Office.ribbon.requestUpdate({
//         tabs: [
//             {
//                 id: "SyftTab",
//                 groups: [
//                     {
//                         id: "CommandsGroup",
//                         controls: [
//                             {
//                                 id: "LoginButton",
//                                 enabled: true
//                             }
//                         ]
//                     }
//                 ]
//             }
//         ]
//     });
// }
// function eb(event) {
//     enableButton();
//     event.completed();
// }
// function db(event) {
//     disableButton();
//     event.completed();
// }