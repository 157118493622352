import { IEntityVL, IConfigStore, IVL } from "./InterfaceStore"

const getResources = async () => {
    const resourcesStore = await OfficeRuntime.storage.getItem("resources");
    const resources = JSON.parse(resourcesStore);
    return resources;
}

const setResources = async (resources) => {
    await OfficeRuntime.storage.setItem("resources", resources);
}

const removeResources = async () => {
    await OfficeRuntime.storage.removeItem("resources");
}

const removeAllResources = async () => {
    const allKeys = await OfficeRuntime.storage.getKeys();
    const keysToRemove = allKeys.filter(key => key !== "firstRunComplete");
    await OfficeRuntime.storage.removeItems(keysToRemove);
}

const removeAllKeys = async () => {
    const allKeys = await OfficeRuntime.storage.getKeys();
    await OfficeRuntime.storage.removeItems(allKeys);
}

const getFirstRunStore = async () => {
    const firstRun = !(await OfficeRuntime.storage.getItem("firstRunComplete"));
    return firstRun;
}

const setFirstRunStore = async () => {
    await OfficeRuntime.storage.setItem("firstRunComplete", "true");
}

const getSelectedEntityStore: () => Promise<IEntityVL> = async () => {
    let selectedEntityStore = JSON.parse(await OfficeRuntime.storage.getItem("selectedEntity"));
    return selectedEntityStore;
}

const setSelectedEntityStore: (selectedEntity: IEntityVL) => Promise<void> = async (selectedEntity: IEntityVL) => {
    await OfficeRuntime.storage.setItem("selectedEntity", JSON.stringify(selectedEntity));
}

const getRefreshingEntitiesStore: () => Promise<IEntityVL[]> = async () => {
    let refreshingEntitiesStore = JSON.parse(await OfficeRuntime.storage.getItem("refreshingEntities")) as IEntityVL[];
    if (refreshingEntitiesStore) {
        return refreshingEntitiesStore;
    } else {
        return [];
    }
}

const addToRefreshingEntitiesStore: (refreshEntity: IEntityVL) => Promise<void> = async (refreshEntity: IEntityVL) => {
    let refreshingEntitiesStore = await getRefreshingEntitiesStore();
    await OfficeRuntime.storage.setItem("refreshingEntities", JSON.stringify([...refreshingEntitiesStore, refreshEntity]));
}

const removeFromRefreshingEntitiesStore: (refreshEntity: IEntityVL) => Promise<void> = async (refreshEntity: IEntityVL) => {
    let refreshingEntitiesStore = await getRefreshingEntitiesStore();
    await OfficeRuntime.storage.setItem("refreshingEntities", JSON.stringify(refreshingEntitiesStore.filter(entity => entity.value !== refreshEntity.value)));
}

const getConfigStore: () => Promise<IConfigStore> = async () => {
    let configStore = JSON.parse(await OfficeRuntime.storage.getItem("customConfigs"));
    return configStore;
}

const setConfigStore: (entity: IEntityVL, layouts: { pnl: IVL, bs: IVL, pnlOptions: IVL[], bsOptions: IVL[], fourwayForecast: IVL, fourwayForecastOptions: IVL[], buildPnl: IVL, buildPnlOptions: IVL[], buildBs: IVL, buildBsOptions: IVL[] }) => Promise<void> = async (entity: IEntityVL, layouts: { pnl: IVL, bs: IVL, pnlOptions: IVL[], bsOptions: IVL[], fourwayForecast: IVL, fourwayForecastOptions: IVL[], buildPnl: IVL, buildPnlOptions: IVL[], buildBs: IVL, buildBsOptions: IVL[] }) => {
    let configStore = await getConfigStore();
    await OfficeRuntime.storage.setItem("customConfigs", JSON.stringify({ ...configStore, [entity.value]: layouts }));
}

export default class DataStore {
    static getResources = getResources;
    static setResources = setResources;
    static removeResources = removeResources;
    static removeAllResources = removeAllResources;
    static removeAllKeys = removeAllKeys;
    static getFirstRunStore = getFirstRunStore;
    static setFirstRunStore = setFirstRunStore;
    static getSelectedEntityStore = getSelectedEntityStore;
    static setSelectedEntityStore = setSelectedEntityStore;
    static getRefreshingEntitiesStore = getRefreshingEntitiesStore;
    static addToRefreshingEntitiesStore = addToRefreshingEntitiesStore;
    static removeFromRefreshingEntitiesStore = removeFromRefreshingEntitiesStore;
    static getConfigStore = getConfigStore;
    static setConfigStore = setConfigStore;
}